.App {
  text-align: center;
  font-family: "Inter", Sans-serif;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fill-height-or-more {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.fill-height-or-more > div {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.landing,
.tech,
.work {
  background-color: #072031;
  color: white;
}

.fullName {
  font-size: 3em;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 5px;
}

.firstName {
  color: #eeaa00;
  font-weight: 800;
}

.lastName {
  color: #ffffff;
  font-weight: 100;
}

.title {
  font-weight: 400;
  margin-top: 1em;
  margin-bottom: 2em;
  color: #eeaa00;
}

@media (max-width: 460px) {
  .title {
    margin-left: 20%;
    margin-right: 20%;
  }
}

.intro {
  margin: 0 20%;
}

.arrow-down {
  color: #ffffff;
}

.arrow-down:hover {
  color: #eeaa00;
}

.arrow-down-i {
  color: #072031;
}

.arrow-down-i:hover {
  color: #cccccc;
}

.home-page {
  margin-left: 50%;
  position: absolute;
  bottom: 20px !important;
}

.other-page {
  position: relative;
  bottom: 40px !important;
  margin-bottom: -24px;
}

.about-me {
  font-weight: 400;
  font-size: initial;
}

.work-header {
  color: #eeaa00;
  font-size: medium;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.125rem;
}

.work-title {
  font-weight: 400;
  font-size: medium;
}

.work-company {
  font-size: small;
  font-weight: 400;
}

.work-duration {
  font-weight: 100;
  font-size: small;
}

.work-ux {
  text-decoration: none;
  color: #fff;
}

.work-ux:hover {
  text-decoration: none;
  color: #eeaa00;
}

.footer {
  font-size: small;
}

.social {
  color: #072031;
  margin: 2px 5px;
}

.social:hover {
  color: #eeaa00 !important;
}
